// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as vars from './vars.json'

const crypto = require('crypto')
const key = 'fjkmnsdfghnbfdvbndbhnfbghjnbvnhf'
const iv = crypto.randomBytes(16)
const accounting = require('accounting')

export function encrypt (objEnter) {
  const text = JSON.stringify(objEnter)
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv)
  let encrypted = cipher.update(text)
  encrypted = Buffer.concat([encrypted, cipher.final()])

  const obj = {
    iv: iv.toString('hex'),
    encryptedData: encrypted.toString('hex'),
  }
  return obj
}

export function decrypt (obj) {
  const iv = Buffer.from(obj.iv, 'hex')
  const encryptedText = Buffer.from(obj.encryptedData, 'hex')
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv)
  let decrypted = decipher.update(encryptedText)
  decrypted = Buffer.concat([decrypted, decipher.final()])
  const txt = JSON.parse(decrypted.toString())
  return txt
}

export function validIdentNumber (type, number) {
  if (type) {
    const cedula = number
    let digitoRegion = 0
    let correct
    digitoRegion = cedula.substring(0, 2)
    if (digitoRegion >= 1 && digitoRegion <= 24) {
      let total = 0
      for (let i = 0; i < cedula.length; i++) {
        if (i % 2 === 0) {
          let aux = cedula[i] * 2
          if (aux > 9) aux -= 9
          total += aux
        } else {
          if (i !== 9) {
            total += parseInt(cedula[i])
          }
        }
      }
      const valid = Math.ceil(total / 10) * 10 - total
      const ultDigito = cedula.split('')[cedula.length - 1]
      if (Number(ultDigito) === Number(valid)) {
        correct = 1
      } else {
        correct = 2
      }
    } else {
      correct = 3
    }
    return correct
  } else {
    const dto = number.length
    let valor
    let acu = 0
    if (number === '') {
      return 0
    } else {
      for (let i = 0; i < dto; i++) {
        valor = number.substring(i, i + 1)
        if (valor === '0' || valor === '1' || valor === '2' || valor === '3' || valor === '4' || valor === '5' || valor === '6' || valor === '7' || valor === '8' || valor === '9') {
          acu = acu + 1
        }
      }
      if (acu === dto) {
        while (number.substring(10, 13) !== '001') {
          return 0
        }
        while (parseInt(number.substring(0, 2)) > 24) {
          return 0
        }
      } else {
        return 0
      }
      return 1
    }
  }
}

export function JSONToCSVConvertor (JSONData, ReportTitle, ShowLabel) {
  // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

  let CSV = 'sep=,' + '\r\n\n'

  // This condition will generate the Label/Header
  if (ShowLabel) {
    let row = ''

    // This loop will extract the label from 1st index of on array
    for (const index in arrData[0]) {
      // Now convert each value to string and comma-seprated
      row += index + ','
    }

    row = row.slice(0, -1)

    // append Label row with line break
    CSV += row + '\r\n'
  }

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = ''

    // 2nd loop will extract each column and convert it in string comma-seprated
    for (const index in arrData[i]) {
      row += '"' + arrData[i][index] + '",'
    }

    row.slice(0, row.length - 1)

    // add a line break after each row
    CSV += row + '\r\n'
  }

  if (CSV === '') {
    alert('Invalid data')
    return
  }

  // Generate a file name
  let fileName = ''
  // this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.toString().replace(/ /g, '_')

  // Initialize file format you want csv or xls
  const uri = 'data:text/csv;charset=utf-8,' + escape(CSV)

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  // this trick will generate a temp <a /> tag
  const link = document.createElement('a')
  link.href = uri

  // set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden'
  link.download = fileName + '.csv'

  // this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function getAge (dateString) {
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  let m = today.getMonth() - birthDate.getMonth()
  let day = today.getDate() - birthDate.getDate()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  if (m < 0) {
    m = m + 12
  }
  if (day < 0) {
    day = day + 30
    m = m - 1
  }
  return [age, m, day]
}

export function logOut () {
  firebase.auth().signOut().then(() => {
    localStorage.clear()
    localStorage.clear()
    location.reload(true)
  })
}

export function base64ToArrayBuffer (data) {
  const binaryString = window.atob(data)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

export function specialCharacters (s) {
  let r = s.toLowerCase()
  r = r.replace(new RegExp(/\s/g), '')
  r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
  r = r.replace(new RegExp(/æ/g), 'ae')
  r = r.replace(new RegExp(/ç/g), 'c')
  r = r.replace(new RegExp(/[èéêë]/g), 'e')
  r = r.replace(new RegExp(/[ìíîï]/g), 'i')
  r = r.replace(new RegExp(/ñ/g), 'n')
  r = r.replace(new RegExp(/[òóôõö]/g), 'o')
  r = r.replace(new RegExp(/œ/g), 'oe')
  r = r.replace(new RegExp(/[ùúûü]/g), 'u')
  r = r.replace(new RegExp(/[ýÿ]/g), 'y')
  r = r.replace(new RegExp(/\W/g), '')
  return r
}

export function specialCharactersTildes (s) {
  let r = s.toLowerCase()
  r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
  r = r.replace(new RegExp(/[èéêë]/g), 'e')
  r = r.replace(new RegExp(/ñ/g), 'n')
  r = r.replace(new RegExp(/[òóôõö]/g), 'o')
  r = r.replace(new RegExp(/[ùúûü]/g), 'u')
  return r.toUpperCase()
}

export function printDataOpenTable (data, profile, myWindow) {
  return new Promise(resolve => {
    const tableSecuential = data.secuential ? (data.secuential !== '' ? data.secuential : '--') : '--'
    const mesaView = data.number ? (data.number !== '' ? data.number : '--') : '--'
    const obsView = data.observations ? (data.observations !== '' ? data.observations : '--') : '--'
    const timeView = data.createTime ? (data.createTime !== '' ? data.createTime : '--:--:--') : '--:--:--'
    const dateView = data.createDate ? (data.createDate !== '' ? data.createDate : '--:--:--') : '--:--:--'
    const waitressView = data.waitress ? (data.waitress !== '' ? data.waitress : '--') : '--'
    let strProds = ''
    for (let i = 0; i < data.lstProductos.length; i++) {
      // const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].valorUnitario)) * parseFloat(data.lstProductos[i].cantidad), 3)
      strProds = strProds + ' <tr> ' +
        '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
        '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
        // '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="precio">$' + strActiveValue + '</td> ' +
        '    </tr> '
    }
    let strHtml = '<html><head><title>my div</title>' +
      '</head><body >' +
      '<div>' +
      // '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;">' + profile.razonSocial + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>FECHA: </strong>' + dateView + '</p>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>HORA: </strong>' + timeView + '</p>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>MESA: </strong>' + mesaView + '</p>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>PEDIDO: </strong>' + tableSecuential + '</p>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>MESERA: </strong>' + waitressView + '</p>' +
      (data.companyData ? (data.companyData.calificacionArtesanal !== '' ? '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>Calificación Artesanal: </strong>' + data.companyData.calificacionArtesanal + '</p>' : '') : '') +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">----------------------------------------------------------------</p>' +
      '<br>' +
      '<table> ' +
      '<thead> ' +
      '    <tr> ' +
      '        <th style="padding: 0 15px 0 0; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="cantidad">CANT.</th> ' +
      '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="producto">DETALLE</th> ' +
      // '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="precio">TOT.</th> ' +
      '    </tr> ' +
      '</thead> ' +
      '<tbody> ' +
      strProds +
      '</tbody> ' +
      '</table> ' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>OBSERVACIONES: </strong></p>' +
      '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 14px; font-family: Arial, sans-serif;">' + obsView + '</p>' +
      '<br>' +
      '<br>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">----------------------------------------------------------------</p>' +
      '</body></html>'
    strHtml = specialCharactersTildes(strHtml)
    myWindow.document.write(strHtml)
    myWindow.document.close()
    myWindow.print()
    myWindow.close()
    resolve(true)
  })
}

export function printData (data, profile, myWindow, imgBs64) {
  return new Promise(resolve => {
    const timeView = data.createTime ? (data.createTime !== '' ? data.createTime : '--:--:--') : '--:--:--'
    // if (profile.comanda) {
    //   const mesaView = data.mesa ? (data.mesa !== '' ? data.mesa : '--') : '--'
    //   const obsView = data.observaciones ? (data.observaciones !== '' ? data.observaciones : '--') : '--'
    //   let strProds = ''
    //   for (let i = 0; i < data.lstProductos.length; i++) {
    //     const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].valorUnitario)) * parseFloat(data.lstProductos[i].cantidad), 3)
    //     strProds = strProds + ' <tr> ' +
    //       '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
    //       '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
    //       '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="precio">$' + strActiveValue + '</td> ' +
    //       '    </tr> '
    //   }
    //   let strHtml = '<html><head><title>my div</title>' +
    //     '</head><body >' +
    //     '<div>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;">' + profile.razonSocial + '</p>' +
    //     '<br>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>HORA: </strong>' + timeView + '</p>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>MESA: </strong>' + mesaView + '</p>' +
    //     '<br>' +
    //     '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">----------------------------------------------------------------</p>' +
    //     '<table> ' +
    //     '<thead> ' +
    //     '    <tr> ' +
    //     '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="cantidad">CANT</th> ' +
    //     '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="producto">PRODUCTO</th> ' +
    //     '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="precio">TOT.</th> ' +
    //     '    </tr> ' +
    //     '</thead> ' +
    //     '<tbody> ' +
    //     strProds +
    //     '</tbody> ' +
    //     '</table> ' +
    //     '<br>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>OBSERVACIONES: </strong></p>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 14px; font-family: Arial, sans-serif;">' + obsView + '</p>' +
    //     '<br>' +
    //     '<br>' +
    //     '<br>' +
    //     '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">----------------------------------------------------------------</p>' +
    //     '</body></html>'
    //   strHtml = specialCharactersTildes(strHtml)
    //   myWindow.document.write(strHtml)
    //   myWindow.document.close()
    //   myWindow.print()
    //   myWindow.close()
    //   resolve(true)
    // } else {
    if (profile.printNormal) {
      let srtObligado = ''
      let srtAgente = ''
      let srtContEspe = ''
      let strRegMic = ''
      let strRegEmp = ''
      let strRegNeg = ''
      if (data.companyData) {
        srtObligado = data.companyData.obligadoContabilidad ? '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;"><strong>OBLIGADO A LLEVAR CONTABILIDAD: </strong>SI</p>' : '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;"><strong>OBLIGADO A LLEVAR CONTABILIDAD: </strong>NO</p>'
        srtAgente = (data.companyData.agenteRetencion.toString() !== 'NO APLICA' && data.companyData.agenteRetencion.toString() !== '') ? '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">AGENTE DE RETENCIÓN</p>' +
          '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;"><strong>RESOLUCIÓN No. </strong> ' + data.companyData.agenteRetencion + '</p>' : ''
        srtContEspe = (data.companyData.contribuyenteEspecial.toString() !== 'NO APLICA' && data.companyData.contribuyenteEspecial.toString() !== '') ? '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">Contribuyente Especial Nro.</p>' +
          '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">' + data.companyData.contribuyenteEspecial + '</p>' : ''
        strRegMic = data.companyData.regimenMicroempresas ? '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">CONTRIBUYENTE RÉGIMEN MICROEMPRESAS</p>' : ''
        strRegEmp = data.companyData.regimenRimpeEmprendedor ? '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">CONTRIBUYENTE RÉGIMEN RIMPE</p>' : ''
        strRegNeg = data.companyData.regimenRimpeNegocio ? '<p style="text-align: RIGHT; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">CONTRIBUYENTE NEGOCIO POPULAR - RÉGIMEN RIMPE</p>' : ''
      }

      let strRegMicro = ''

      if (strRegMic !== '') {
        strRegMicro = strRegMic
      }
      if (strRegEmp !== '') {
        strRegMicro = strRegEmp
      }
      if (strRegNeg !== '') {
        strRegMicro = strRegNeg
      }

      const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
      let strProds = ''
      for (let i = 0; i < data.lstProductos.length; i++) {
        const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].valorUnitario)) * parseFloat(data.lstProductos[i].cantidad), 3)
        strProds = strProds + ' <tr> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + (data.lstProductos[i].descuento ? data.lstProductos[i].descuento : '0.00') + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].valorUnitario + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="precio">' + strActiveValue + '</td> ' +
          '    </tr> '
      }
      const strImg = '<img src="' + imgBs64 + '" style="max-width: 250px">'
      let strHtmlNormal = '<HTML> ' +
        '   <HEAD> ' +
        '      <TITLE>' + 'FACTURA DE VENTA' + '</TITLE> ' +
        '      <style> ' +
        '         * { ' +
        '         box-sizing: border-box; ' +
        '         } ' +
        '         /* Create two equal columns that floats next to each other */ ' +
        '         .column { ' +
        '         float: left; ' +
        '         width: 50%; ' +
        '         padding: 10px; /* Should be removed. Only for demonstration */ ' +
        '         } ' +
        '         /* Clear floats after the columns */ ' +
        '         .row:after { ' +
        '         content: ""; ' +
        '         display: table; ' +
        '         clear: both; ' +
        '         } ' +
        '        #customers { ' +
        '          font-family: Arial, Helvetica, sans-serif; ' +
        '          border-collapse: collapse; ' +
        '          width: 100%; ' +
        '        } ' +
        '         ' +
        '        #customers td, #customers th { ' +
        '          border: 1px solid #ddd; ' +
        '          padding: 8px; ' +
        '        } ' +
        '         ' +
        '        #customers tr:nth-child(even){background-color: #f2f2f2;} ' +
        '         ' +
        '        #customers tr:hover {background-color: #ddd;} ' +
        '         ' +
        '        #customers th { ' +
        '          padding-top: 12px; ' +
        '          padding-bottom: 12px; ' +
        '          text-align: left; ' +
        '          background-color: #04AA6D; ' +
        '          color: white; ' +
        '        } ' +
        '      </style> ' +
        '   </HEAD> ' +
        '   <BODY > ' +
        '      <DIV style="background: white;"> ' +
        '         <DIV CLASS="ROW">' +
        '             <DIV CLASS="COLUMN">' +
        'IMG-USER-VAL' +
        '             </div>' +
        '             <DIV CLASS="COLUMN">' +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333; font-weight: bold;">' + 'FACTURA DE VENTA' + '</P> ' +
        '         <BR> ' +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG style="color: #aaaaab;">' + 'FACTURA' + ': </STRONG>' + data.numsecuencial + '</P> ' +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG style="color: #aaaaab;">FECHA DE EMISIÓN: </STRONG>' + data.fechaEmision + '</P> ' +
        srtObligado +
        srtAgente +
        srtContEspe +
        strRegMicro +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;"><strong>NÚMERO DE AUTORIZACIÓN</strong></p>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">' + data.claveAcceso + '</p>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;"><strong>CLAVE DE ACCESO SRI</strong></p>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;">' + data.claveAcceso + '</p>' : '') +
        (data.companyData ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #333333;"><strong>AMBIENTE: </strong>' + data.companyData.ambiente.texto + '</p>' : '') +
        (data.companyData ? (data.companyData.calificacionArtesanal !== '' ? '<p style="margin: 2px; font-size: 10px; font-family: Arial, sans-serif;"><strong>Calificación Artesanal: </strong>' + data.companyData.calificacionArtesanal + '</p>' : '') : '') +
        '             </div>' +
        '          </div>' +
        '         <BR> ' +
        '         <div class="row"> ' +
        '            <div class="column"> ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #aaaaab; font-weight: bold;">DATOS DEL EMISOR</P> ' +
        '              <br> ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>RAZÓN SOCIAL: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.razonSocial : profile.razonSocial2) + '</P>          ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>RUC: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.rucEstablecimieto : profile.rucEstablecimieto2) + '</P> ' +
        '         <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>TELEFONO: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.telefonoEstablecimiento : profile.telefonoEstablecimiento2) + '</P> ' +
        '         <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>DIRECCIÓN: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.direccionEstablecimiento : profile.direccionEstablecimiento2) + '</P> ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>EMAIL: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.correoEstablecimiento : profile.correoEstablecimiento2) + '</P> ' +
        '            </div> ' +
        '            <div class="column"> ' +
        '               <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #aaaaab; font-weight: bold;">DATOS DEL CLIENTE</P> ' +
        '              <br> ' +
        '              <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>CLIENTE: </STRONG>' + strClient + '</P> ' +
        '         <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>RUC/CI: </STRONG>' + data.client.ide + '</P> ' +
        '              <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>TELÉFONO: </STRONG>' + data.client.cellphone + '</P> ' +
        '              <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>DIRECCIÓN: </STRONG>' + data.client.address + '</P> ' +
        '         <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>EMAIL: </STRONG>' + data.client.mail + '</P> ' +
        '            </div> ' +
        '         </div> ' +
        '         <TABLE id="customers"> ' +
        '            <THEAD> ' +
        '               <TR> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="CANTIDAD">CANT</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRODUCTO">PRODUCTO</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRODUCTO">DCTO.</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRODUCTO">P.U.</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRECIO">TOT.</TH> ' +
        '               </TR> ' +
        '            </THEAD> ' +
        '            <TBODY> ' +
        strProds +
        '            </TBODY> ' +
        '         </TABLE> ' +
        '         <BR> ' +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>SUB TOTAL: </STRONG>$' + data.subtotal + '</P> ' +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>DCTO: </STRONG>$' + data.totdcto + '</P> ' +
        (data.quitIvaNote ? '' : '<P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>IVA ' + vars.validIva + '%: </STRONG>$' + (parseFloat(data.total) - parseFloat(data.subtotal)).toFixed(2) + '</P> ') +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>TOTAL: </STRONG>$' + data.total + '</P> ' +
        '      </DIV> ' +
        '      <p style="font-weight: bold; font-size: 12px;">Observaciones:</p>' +
        '      <p style="font-size: 10px; max-width: 350px;">' + (data.observations ? data.observations : '') + '</p>' +
        '     <div style="position: absolute; bottom: 0px;"> ' +
        '          <P STYLE="MARGIN: 2PX; FONT-SIZE: 8PX; font-family: Arial, sans-serif; font-weight: bold;" CLASS="CENTRADO">¡GRACIAS POR SU COMPRA! <BR>J2RTECH.COM</P> ' +
        '        </div> ' +
        '   </BODY> ' +
        '</HTML>'

      strHtmlNormal = specialCharactersTildes(strHtmlNormal)
      strHtmlNormal = strHtmlNormal.replace('IMG-USER-VAL', strImg)
      myWindow.document.write(strHtmlNormal)
      myWindow.document.close()
      setTimeout(() => {
        myWindow.print()
        myWindow.close()
        resolve(true)
      }, 1000)
    } else {
      let srtObligado = ''
      let srtAgente = ''
      let srtContEspe = ''
      let strRegMic = ''
      let strRegEmp = ''
      let strRegNeg = ''
      if (data.companyData) {
        srtObligado = data.companyData.obligadoContabilidad ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>OBLIGADO A LLEVAR CONTABILIDAD: </strong>SI</p>' : '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>OBLIGADO A LLEVAR CONTABILIDAD: </strong>NO</p>'
        srtAgente = (data.companyData.agenteRetencion.toString() !== 'NO APLICA' && data.companyData.agenteRetencion.toString() !== '') ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">AGENTE DE RETENCIÓN</p>' +
          '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>RESOLUCIÓN No. </strong> ' + data.companyData.agenteRetencion + '</p>' : ''
        srtContEspe = (data.companyData.contribuyenteEspecial.toString() !== 'NO APLICA' && data.companyData.contribuyenteEspecial.toString() !== '') ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">Contribuyente Especial Nro.</p>' +
          '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">' + data.companyData.contribuyenteEspecial + '</p>' : ''
        strRegMic = data.companyData.regimenMicroempresas ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">CONTRIBUYENTE RÉGIMEN MICROEMPRESAS</p>' : ''
        strRegEmp = data.companyData.regimenRimpeEmprendedor ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">CONTRIBUYENTE RÉGIMEN RIMPE</p>' : ''
        strRegNeg = data.companyData.regimenRimpeNegocio ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">CONTRIBUYENTE NEGOCIO POPULAR - RÉGIMEN RIMPE</p>' : ''
      }

      let strRegMicro = ''

      if (strRegMic !== '') {
        strRegMicro = strRegMic
      }
      if (strRegEmp !== '') {
        strRegMicro = strRegEmp
      }
      if (strRegNeg !== '') {
        strRegMicro = strRegNeg
      }

      const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
      let strProds = ''
      for (let i = 0; i < data.lstProductos.length; i++) {
        const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].valorUnitario)) * parseFloat(data.lstProductos[i].cantidad), 3)
        strProds = strProds + ' <tr> ' +
          '        <td style="font-size: 12px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
          '        <td style="font-size: 11px; font-family: Arial, sans-serif; max-width: 100px; word-wrap: break-word;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
          '        <td style="font-size: 12px; font-family: Arial, sans-serif; max-width: 75px; word-wrap: break-word;" class="producto">' + (data.lstProductos[i].descuento ? (parseFloat(data.lstProductos[i].descuento).toFixed(2)).toString() : '0.00') + '</td> ' +
          '        <td style="font-size: 12px; font-family: Arial, sans-serif; max-width: 75px; word-wrap: break-word;" class="producto">' + (parseFloat(data.lstProductos[i].valorUnitario).toFixed(2)).toString() + '</td> ' +
          '        <td style="font-size: 12px; font-family: Arial, sans-serif; max-width: 75px; word-wrap: break-word;" class="precio">' + (parseFloat(strActiveValue).toFixed(2)).toString() + '</td> ' +
          '    </tr> '
      }
      let strHtml = '<html><head><title>my div</title>' +
        '</head><body >' +
        '<div>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 14px; font-family: Arial, sans-serif;">' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.razonSocial : profile.razonSocial2) + '</p>' +
        '<br>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>RUC: </strong>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.rucEstablecimieto : profile.rucEstablecimieto2) + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TELÉFONO: </strong>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.telefonoEstablecimiento : profile.telefonoEstablecimiento2) + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.direccionEstablecimiento : profile.direccionEstablecimiento2) + '</p>' +
        (data.companyData ? '<br>' : '') +
        srtObligado +
        srtAgente +
        srtContEspe +
        strRegMicro +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>FACTURA: </strong>' + data.numsecuencial + '</p>' +
        (data.claveAcceso ? '<br>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>NÚMERO DE AUTORIZACIÓN</strong></p>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #000; letter-spacing: 1px; word-wrap: break-word;">' + data.claveAcceso + '</p>' : '') +
        (data.claveAcceso ? '<br>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>CLAVE DE ACCESO SRI</strong></p>' : '') +
        (data.claveAcceso ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif; color: #000; letter-spacing: 1px; word-wrap: break-word;">' + data.claveAcceso + '</p>' : '') +
        (data.claveAcceso ? '<br>' : '') +
        (data.companyData ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>AMBIENTE: </strong>' + data.companyData.ambiente.texto + '</p>' : '') +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>FECHA: </strong>' + data.fechaEmision + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>HORA: </strong>' + timeView + '</p>' +
        '<p style="margin: 2px; font-size: 10px; font-family: Arial, sans-serif;"><strong>M. PAGO: </strong>' + data.formapago.formapago + '</p>' +
        (data.companyData ? (data.companyData.calificacionArtesanal !== '' ? '<p style="margin: 2px; font-size: 10px; font-family: Arial, sans-serif;"><strong>Calificación Artesanal: </strong>' + data.companyData.calificacionArtesanal + '</p>' : '') : '') +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>CLIENTE: </strong>' + strClient + '</p>' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>RUC/CI: </strong>' + data.client.ide + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>DIR. </strong>' + data.client.address + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>EMAIL: </strong>' + data.client.mail + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TLF: </strong>' + data.client.cellphone + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<table> ' +
        '<thead> ' +
        '    <tr> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="cantidad">CANT</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">PRODUCTO</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">DCTO.</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">P.U.</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="precio">TOT.</th> ' +
        '    </tr> ' +
        '</thead> ' +
        '<tbody> ' +
        strProds +
        '</tbody> ' +
        '</table> ' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>SUB TOTAL: </strong>$' + data.subtotal + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>DCTO: </strong>$' + data.totdcto + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>IVA ' + vars.validIva + '%: </strong>$' + (parseFloat(data.total) - parseFloat(data.subtotal)).toFixed(2) + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TOTAL: </strong>$' + data.total + '</p>' +
        '      <p style="font-weight: bold; font-size: 12px;">Observaciones:</p>' +
        '      <p style="font-size: 10px; max-width: 350px;">' + (data.observations ? data.observations : '') + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;" class="centrado">¡GRACIAS POR SU COMPRA! ' +
        '<br>J2RTECH.COM</p></div>' +
        '<br>' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '</body></html>'
      strHtml = specialCharactersTildes(strHtml)
      myWindow.document.write(strHtml)
      myWindow.document.close()
      myWindow.print()
      myWindow.close()
      resolve(true)
    }
    // }
  })
}

export function printDataNote (data, profile, name, imgBs64, myWindow) {
  return new Promise(resolve => {
    // if (profile.comanda) {
    //   const tableSecuential = data.secuential ? (data.secuential !== '' ? data.secuential : '--') : '--'
    //   const mesaView = data.mesa ? (data.mesa !== '' ? data.mesa : '--') : '--'
    //   const obsView = data.observaciones ? (data.observaciones !== '' ? data.observaciones : '--') : '--'
    //   const timeView = data.createTime ? (data.createTime !== '' ? data.createTime : '--:--:--') : '--:--:--'
    //   let strProds = ''
    //   for (let i = 0; i < data.lstProductos.length; i++) {
    //     const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].valorUnitario)) * parseFloat(data.lstProductos[i].cantidad), 3)
    //     strProds = strProds + ' <tr> ' +
    //       '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
    //       '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
    //       '        <td style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="precio">$' + strActiveValue + '</td> ' +
    //       '    </tr> '
    //   }
    //   let strHtml = '<html><head><title>my div</title>' +
    //     '</head><body >' +
    //     '<div>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;">' + profile.razonSocial + '</p>' +
    //     '<br>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>HORA: </strong>' + timeView + '</p>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>MESA: </strong>' + mesaView + '</p>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>PEDIDO: </strong>' + tableSecuential + '</p>' +
    //     '<br>' +
    //     '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">----------------------------------------------------------------</p>' +
    //     '<table> ' +
    //     '<thead> ' +
    //     '    <tr> ' +
    //     '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="cantidad">CANT</th> ' +
    //     '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="producto">PRODUCTO</th> ' +
    //     '        <th style="font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;" class="precio">TOT.</th> ' +
    //     '    </tr> ' +
    //     '</thead> ' +
    //     '<tbody> ' +
    //     strProds +
    //     '</tbody> ' +
    //     '</table> ' +
    //     '<br>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;"><strong>OBSERVACIONES: </strong></p>' +
    //     '<p style="text-align: left; margin: 2px; font-weight: bold; font-size: 14px; font-family: Arial, sans-serif;">' + obsView + '</p>' +
    //     '<br>' +
    //     '<br>' +
    //     '<br>' +
    //     '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">----------------------------------------------------------------</p>' +
    //     '</body></html>'
    //   strHtml = specialCharactersTildes(strHtml)
    //   myWindow.document.write(strHtml)
    //   myWindow.document.close()
    //   myWindow.print()
    //   myWindow.close()
    //   resolve(true)
    // } else {
    if (profile.printNormal) {
      const myWindow = window.open('', name, 'fullscreen="yes"')
      const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
      let strProds = ''
      for (let i = 0; i < data.lstProductos.length; i++) {
        const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].producto.activeWValue)) * parseFloat(data.lstProductos[i].cantidad), 3)
        strProds = strProds + ' <tr> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + (data.lstProductos[i].descuento ? data.lstProductos[i].descuento : '0.00') + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].valorUnitario + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="precio">' + strActiveValue + '</td> ' +
          '    </tr> '
      }
      const strImg = '<img src="' + imgBs64 + '" style="max-width: 250px">'
      const orderNoteTxt = (profile.showOrderNoteTxt === true) ? '<p style="text-align: RIGHT; margin: 2px; font-weight: bold; font-size: 17px; font-family: Arial, sans-serif;">' + 'NOTA DE PEDIDO' + '</p>' + '<br>' : '<P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333; font-weight: bold;">' + 'NOTA DE VENTA' + '</P> '
      let strHtmlNormal = '<HTML> ' +
        '   <HEAD> ' +
        '      <TITLE>' + 'NOTA DE VENTA' + '</TITLE> ' +
        '      <style> ' +
        '         * { ' +
        '         box-sizing: border-box; ' +
        '         } ' +
        '         /* Create two equal columns that floats next to each other */ ' +
        '         .column { ' +
        '         float: left; ' +
        '         width: 50%; ' +
        '         padding: 10px; /* Should be removed. Only for demonstration */ ' +
        '         } ' +
        '         /* Clear floats after the columns */ ' +
        '         .row:after { ' +
        '         content: ""; ' +
        '         display: table; ' +
        '         clear: both; ' +
        '         } ' +
        '        #customers { ' +
        '          font-family: Arial, Helvetica, sans-serif; ' +
        '          border-collapse: collapse; ' +
        '          width: 100%; ' +
        '        } ' +
        '         ' +
        '        #customers td, #customers th { ' +
        '          border: 1px solid #ddd; ' +
        '          padding: 8px; ' +
        '        } ' +
        '         ' +
        '        #customers tr:nth-child(even){background-color: #f2f2f2;} ' +
        '         ' +
        '        #customers tr:hover {background-color: #ddd;} ' +
        '         ' +
        '        #customers th { ' +
        '          padding-top: 12px; ' +
        '          padding-bottom: 12px; ' +
        '          text-align: left; ' +
        '          background-color: #04AA6D; ' +
        '          color: white; ' +
        '        } ' +
        '      </style> ' +
        '   </HEAD> ' +
        '   <BODY > ' +
        '      <DIV style="background: white;"> ' +
        '         <DIV CLASS="ROW">' +
        '             <DIV CLASS="COLUMN">' +
        'IMG-USER-VAL' +
        '             </div>' +
        '             <DIV CLASS="COLUMN">' +
        orderNoteTxt +
        '               <BR> ' +
        '               <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG style="color: #aaaaab;">' + 'NOTA' + ': </STRONG>' + data.numsecuencial + '</P> ' +
        '               <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG style="color: #aaaaab;">FECHA DE EMISIÓN: </STRONG>' + data.fechaEmision + '</P> ' +
        (data.companyData ? (data.companyData.calificacionArtesanal !== '' ? '<p style="margin: 2px; font-size: 10px; font-family: Arial, sans-serif;"><strong>Calificación Artesanal: </strong>' + data.companyData.calificacionArtesanal + '</p>' : '') : '') +
        '             </div>' +
        '          </div>' +
        '         <br> ' +
        '         <BR> ' +
        '         <div class="row"> ' +
        '            <div class="column"> ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #aaaaab; font-weight: bold;">DATOS DEL EMISOR</P> ' +
        '              <br> ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>RAZÓN SOCIAL: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.razonSocial : profile.razonSocial2) + '</P>          ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>RUC: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.rucEstablecimieto : profile.rucEstablecimieto2) + '</P> ' +
        '         <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>TELEFONO: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.telefonoEstablecimiento : profile.telefonoEstablecimiento2) + '</P> ' +
        '         <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>DIRECCIÓN: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.direccionEstablecimiento : profile.direccionEstablecimiento2) + '</P> ' +
        '              <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>EMAIL: </STRONG>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.correoEstablecimiento : profile.correoEstablecimiento2) + '</P> ' +
        '            </div> ' +
        '            <div class="column"> ' +
        '               <P STYLE="TEXT-ALIGN: LEFT; MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #aaaaab; font-weight: bold;">DATOS DEL CLIENTE</P> ' +
        '              <br> ' +
        '              <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>CLIENTE: </STRONG>' + strClient + '</P> ' +
        '         <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>RUC/CI: </STRONG>' + data.client.ide + '</P> ' +
        '              <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>TELÉFONO: </STRONG>' + data.client.cellphone + '</P> ' +
        '              <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>DIRECCIÓN: </STRONG>' + data.client.address + '</P> ' +
        '         <P STYLE="MARGIN: 2PX; FONT-SIZE: 12PX; font-family: Arial, sans-serif; color: #333333;"><STRONG>EMAIL: </STRONG>' + data.client.mail + '</P> ' +
        '            </div> ' +
        '         </div> ' +
        '         <TABLE id="customers"> ' +
        '            <THEAD> ' +
        '               <TR> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="CANTIDAD">CANT</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRODUCTO">PRODUCTO</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRODUCTO">DCTO.</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRODUCTO">P.U.</TH> ' +
        '                  <TH STYLE="FONT-SIZE: 12PX; font-family: Arial, sans-serif;" CLASS="PRECIO">TOT.</TH> ' +
        '               </TR> ' +
        '            </THEAD> ' +
        '            <TBODY> ' +
        strProds +
        '            </TBODY> ' +
        '         </TABLE> ' +
        '         <BR> ' +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>SUB TOTAL: </STRONG>$' + data.subtotal + '</P> ' +
        (data.quitIvaNote ? '' : '<P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>IVA ' + vars.validIva + '%: </STRONG>$' + (parseFloat(data.total) - parseFloat(data.subtotal)).toFixed(2) + '</P> ') +
        '         <P STYLE="TEXT-ALIGN: RIGHT; MARGIN: 2PX; FONT-SIZE: 14PX; font-family: Arial, sans-serif;"><STRONG>TOTAL: </STRONG>$' + data.total + '</P> ' +
        '      </DIV> ' +
        '     <div style="position: absolute; bottom: 0px;"> ' +
        '      <p style="font-weight: bold; font-size: 12px;">Observaciones:</p>' +
        '      <p style="font-size: 10px; max-width: 350px;">' + (data.observations ? data.observations : '') + '</p>' +
        '          <P STYLE="MARGIN: 2PX; FONT-SIZE: 8PX; font-family: Arial, sans-serif; font-weight: bold;" CLASS="CENTRADO">¡GRACIAS POR SU COMPRA! <BR>J2RTECH.COM</P> ' +
        '        </div> ' +
        '   </BODY> ' +
        '</HTML>'
      strHtmlNormal = specialCharactersTildes(strHtmlNormal)
      strHtmlNormal = strHtmlNormal.replace('IMG-USER-VAL', strImg)
      myWindow.document.write(strHtmlNormal)
      myWindow.document.close()
      setTimeout(() => {
        myWindow.print()
        myWindow.close()
        resolve(true)
      }, 1000)
    } else {
      const myWindow = window.open('', name, 'fullscreen="yes"')
      const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
      let strProds = ''
      for (let i = 0; i < data.lstProductos.length; i++) {
        const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].producto.activeWValue)) * parseFloat(data.lstProductos[i].cantidad), 3)
        strProds = strProds + ' <tr> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + (data.lstProductos[i].descuento ? data.lstProductos[i].descuento : '0.00') + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].valorUnitario + '</td> ' +
          '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="precio">' + strActiveValue + '</td> ' +
          '    </tr> '
      }
      let strHtml = '<html><head><title>print-note</title>' +
        '</head><body >' +
        '<div>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 14px; font-family: Arial, sans-serif;">' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.razonSocial : profile.razonSocial2) + '</p>' +
        '<br>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>RUC: </strong>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.rucEstablecimieto : profile.rucEstablecimieto2) + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TELÉFONO: </strong>' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.telefonoEstablecimiento : profile.telefonoEstablecimiento2) + '</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">' + ((!data.cuenta || data.cuenta === undefined || data.cuenta === null || data.cuenta !== '002') ? profile.direccionEstablecimiento : profile.direccionEstablecimiento2) + '</p>' +
        '<br>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TICKET: </strong>' + data.numsecuencial + '</p>' +
        '<br>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>OBSERVACIONES: </strong>' + data.descripcion + '</p>' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>FECHA: </strong>' + data.fechaEmision + '</p>' +
        (data.companyData ? (data.companyData.calificacionArtesanal !== '' ? '<p style="margin: 2px; font-size: 10px; font-family: Arial, sans-serif;"><strong>Calificación Artesanal: </strong>' + data.companyData.calificacionArtesanal + '</p>' : '') : '') +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>CLIENTE: </strong>' + strClient + '</p>' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>RUC/CI: </strong>' + data.client.ide + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>DIR. </strong>' + data.client.address + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>EMAIL: </strong>' + data.client.mail + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TLF: </strong>' + data.client.cellphone + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<table> ' +
        '<thead> ' +
        '    <tr> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="cantidad">CANT</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">PRODUCTO</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">DCTO.</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">P.U.</th> ' +
        '        <th style="font-size: 10px; font-family: Arial, sans-serif;" class="precio">TOT.</th> ' +
        '    </tr> ' +
        '</thead> ' +
        '<tbody> ' +
        strProds +
        '</tbody> ' +
        '</table> ' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>SUB TOTAL: </strong>$' + data.subtotal + '</p>' +
        (data.quitIvaNote ? '' : '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>IVA ' + vars.validIva + '%: </strong>$' + (parseFloat(data.total) - parseFloat(data.subtotal)).toFixed(2) + '</p>') +
        '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TOTAL: </strong>$' + data.total + '</p>' +
        '<br>' +
        '      <p style="font-weight: bold; font-size: 12px;">Observaciones:</p>' +
        '      <p style="font-size: 10px; max-width: 350px;">' + (data.observations ? data.observations : '') + '</p>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;" class="centrado">¡GRACIAS POR SU COMPRA! ' +
        '<br>J2RTECH.COM</p></div>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<br>' +
        '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
        '</body></html>'
      strHtml = specialCharactersTildes(strHtml)
      myWindow.document.write(strHtml)
      myWindow.document.close()
      myWindow.print()
      myWindow.close()
      resolve(true)
    }
    // }
  })
}

export function printVoucher (data, profile, name) {
  return new Promise(resolve => {
    const myWindow = window.open('', name, 'fullscreen="yes"')
    const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
    let strProds = ''
    for (let i = 0; i < data.lstProductos.length; i++) {
      const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].producto.activeWValue)) * parseFloat(data.lstProductos[i].cantidad), 3)
      strProds = strProds + ' <tr> ' +
        '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
        '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
        '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + (data.lstProductos[i].descuento ? data.lstProductos[i].descuento : '0.00') + '</td> ' +
        '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="producto">' + data.lstProductos[i].valorUnitario + '</td> ' +
        '        <td style="font-size: 10px; font-family: Arial, sans-serif;" class="precio">' + strActiveValue + '</td> ' +
        '    </tr> '
    }
    let strHtml = '<html><head><title>print-note</title>' +
      '</head><body >' +
      '<div>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TICKET: </strong>' + data.numsecuencial + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>FECHA: </strong>' + data.fechaEmision + '</p>' +
      (data.companyData ? (data.companyData.calificacionArtesanal !== '' ? '<p style="margin: 2px; font-size: 10px; font-family: Arial, sans-serif;"><strong>Calificación Artesanal: </strong>' + data.companyData.calificacionArtesanal + '</p>' : '') : '') +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>CLIENTE: </strong>' + strClient + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>OBSERVACIONES: </strong>' + data.descripcion + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Arial, sans-serif;"><strong>TOTAL: </strong>$' + data.total + '</p>' +
      '<br>' +
      '      <p style="font-weight: bold; font-size: 12px;">Observaciones:</p>' +
      '      <p style="font-size: 10px; max-width: 350px;">' + (data.observations ? data.observations : '') + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;" class="centrado">¡GRACIAS POR SU COMPRA! ' +
      '<br>Empowered by J2RTECH.COM</p></div>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Arial, sans-serif;">-----------------------------------</p>' +
      '</body></html>'
    strHtml = specialCharactersTildes(strHtml)
    myWindow.document.write(strHtml)
    myWindow.document.close()
    myWindow.print()
    myWindow.close()
    resolve(true)
  })
}
